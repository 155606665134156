<template>
  <v-row justify="center">

    <!-- Caller Verification Method Selection Dialog -->
    <v-dialog
      v-model="dialogSelectMethod"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title>
          <span v-translate='{caller: callerName}'>Choose a method to verify caller: "%{ caller }"</span>
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" v-translate>Factor description</th>
                <th class="text-left ma-0 pa-0" width="1%"></th>
                <th class="text-right" v-translate>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(factor, factorId) in activePendingDisabledFactors" :key="factorId">
                <td v-translate='{factorDescription: $cvutils.getTranslatedFactorDesc(factor)}'>%{ factorDescription }</td>
                <td class="ma-0 pa-0">
                  <v-tooltip v-if="factor.profile.tooltip" right>
                    <template v-slot:activator="{on, attrs}">
                      <v-icon v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>{{ factor.profile.tooltip }}</span>
                  </v-tooltip>
                </td>
                <td class="text-right">
                  <v-btn v-if="factor.status === 'ACTIVE'"
                    small
                    :id="'verify_'+factor.type+'_'+(factor.provider === 'oauth'? factorId : factor.provider)"
                    color="success"
                    @click="verifyCallerInitiate(factorId)"
                  >
                    <translate>Verify</translate>
                  </v-btn>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <div v-on="on" class="d-inline-block">
                        <v-btn v-if="factor.status === 'DISABLED'"
                          small
                          :id="'verify_'+factor.type+'_'+(factor.provider === 'oauth'? factorId : factor.provider)"
                          color="success"
                          :disabled="true"
                        >
                          <translate>Verify</translate>
                        </v-btn>
                      </div>
                    </template>
                    <translate>Verification method disabled due to caller status</translate>
                  </v-tooltip>
                  <v-tooltip v-if="factor.status === 'PENDING_ACTIVATION'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        v-on="on"
                        :id="'verify_pending_'+factor.type+'_'+factor.provider"
                        v-bind="attrs"
                        small
                        label
                        color="info"
                      >
                        <translate>PENDING ACTIVATION</translate>
                      </v-chip>
                    </template>
                    <span><translate>This factor is pending activation</translate></span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            id="verify_choose_cancel"
            @click="$emit('close', 'mfa-verify')"
          >
            <translate>Cancel</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- TOPT, Email, SMS and Security Question Verification Dialog -->
    <v-dialog
      v-model="dialogVerifyResponse"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title v-if="selectedFactor !== null" id="verify_title" v-translate='{factorDescription: $cvutils.getTranslatedFactorDesc(selectedFactor) }'>
          Caller Verification: %{ factorDescription }
        </v-card-title>

        <!-- NOTE: responseHint is translated before it is set, elsewhere in the javascript -->
        <div class="ma-5">

          <!-- Label for factors that require a code to be supplied by the caller: TOTP, SMS, Email -->
          <label
            v-if="otpFactors.includes(selectedFactor.type)" for="callerResponse"
            v-translate="{factor: this.$cvutils.getTranslatedFactorDesc(selectedFactor) }">
            Please enter the %{factor} code
          </label>

          <!-- Label for security question factor -->
          <div><!-- Nested div is required to avoid translation update issue (See HACK in CustomerTable.vue) -->
            <label
            v-if="selectedFactor.type === 'question'" for="callerResponse"
            v-translate="{securityQuestion: $gettext(this.selectedFactor.profile.question)}">
              %{ securityQuestion }
            </label>
          </div>

          <!-- Input field for the caller's response -->
          <v-text-field
            v-model="callerResponse"
            name="callerResponse"
            :label="responseLabel"
            id="verifyResponse"
            ref="callerResponse"
            outlined
            color="#333"
            class="my-4"
            persistent-hint
            :counter="responseLengthMax"
            autocomplete="off"
            :maxlength="responseLengthMax?responseLengthMax:null"
            :clearable="true"
            :type="showResponse ? 'text' : 'password'"
            :hint="responseHint"
            :rules="responseRules"
            :class="{ 'is-invalid': !callerVerified && callerVerified !== null, 'is-valid': callerVerified && callerVerified !== null }"
            @keyup.enter="verifyCallerResponse()"
          >
            <template v-slot:append>
              <!-- control if the caller response input field is starred out or not -->
              <v-icon @click="showResponse = !showResponse">
                {{ showResponse ? 'mdi-eye' : 'mdi-eye-off' }}
              </v-icon>
            </template>
          </v-text-field>

          <div id="verify_result" v-if="callerVerified !== null">
            {{ verificationMessage }}
            <svg v-if="callerVerified !== null && callerVerified"  width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-check-circle-fill float-right mr-3 success--text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
            </svg>
            <svg v-if="callerVerified !== null && !callerVerified"  width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-x-circle-fill float-right mr-3 error--text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
            </svg>
          </div>
        </div>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Button to perform the actual caller verification against the API -->
          <v-btn
            v-if="selectedFactor && (otpFactors.includes(selectedFactor.type) || selectedFactor.type === 'question')"
            color="success"
            id="verify_verify"
            @click="verifyCallerResponse()"
            :loading="verifyInProgress"
            :disabled="responseInvalid"
          >
            <translate>Verify</translate>
          </v-btn>

          <!-- Cancel button to abort this verification dialog -->
          <v-btn
            color="primary"
            id="verify_close"
            @click="dialogVerifyResponse = false"
            :disabled="verifyInProgress"
          >
            <translate>Close</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Device Flow Verification Link Selector -->
    <v-dialog
      v-model="dialogDeviceFlowDelivery"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title v-if="selectedFactor !== null" v-translate='{factorDescription: $cvutils.getTranslatedFactorDesc(selectedFactor) }'>
          Caller Verification: %{ factorDescription }
        </v-card-title>

        <v-card-text>
            <label><translate>Select a method to deliver a verification link to the caller:</translate></label>
          <v-radio-group v-model="deviceDeliveryMethod">
            <v-radio
              key="deliveryLink"
              id="deliveryLink"
              name="deliveryLink"
              :label="$gettext('Show the verification link and manually provide it to the caller.')"
              value="link"
              @click="clearSelectedWebhook()"
            ></v-radio>
            <v-radio
              key="deliveryLinkToWebhook"
              id="deliveryLinkToWebhook"
              name="deliveryLinkToWebhook"
              :label="$gettext('Deliver a verification link to the caller with the selected method.')"
              value="webhook"
            >
            </v-radio>
          </v-radio-group>
          <v-select             
            id="cv_selected_webhook"
            v-model="selectedWebhook"
            :label="$gettext('Choose a delivery method')"
            item-value="value"
            item-text="text"
             v-if="this.deviceDeliveryMethod=='webhook'" :items="webhooks">
          </v-select>

          <v-row class="justify-end mr-2">
            <v-btn
              v-if="selectedWebhook == 'sms' && featureFlags.deviceFlowPhoneOverride"
              color="info"
              id="device_override_phone"
              @click="deviceOverrideAttribute('sms')"
              x-small
            >
              <translate key="phone_change" v-if="deviceSmsNumber || devicePhoneOverride">Change Number</translate>
              <translate key="phone_set" v-else>Set Number</translate>
            </v-btn>
            <v-btn
              v-if="selectedWebhook == 'email' && featureFlags.deviceFlowEmailOverride"
              color="info"
              id="device_override_email"
              @click="deviceOverrideAttribute('email')"
              x-small
            >
              <translate key="email_change" v-if="deviceEmailAddr || deviceEmailOverride">Change Email</translate>
              <translate key="email_set" v-else>Set Email</translate>
            </v-btn>
          </v-row>

        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Button to perform the actual caller verification against the API -->
          <v-btn
            color="success"
            id="device_verify"
            @click="verifyCallerDevice()"
            :disabled="(deviceDeliveryMethod == null) ||
              (deviceDeliveryMethod === 'webhook' && selectedWebhook == null) ||
              (deviceDeliveryMethod === 'webhook' && selectedWebhook === 'sms' && !(devicePhoneOverride || deviceSmsNumber)) ||
              (deviceDeliveryMethod === 'webhook' && selectedWebhook === 'email' && !(deviceEmailOverride || deviceEmailAddr))"
            :loading="verifyInProgress"
          >
            <translate>Verify</translate>
          </v-btn>

          <!-- Cancel button to abort this verification dialog -->
          <v-btn
            color="primary"
            id="device_close"
            @click="dialogDeviceFlowDelivery = false"
            :disabled="verifyInProgress"
          >
            <translate>Close</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- CIBA Optional Message Dialog -->
    <v-dialog
      v-model="dialogCIBAMessage"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title v-if="selectedFactor !== null" v-translate='{factorDescription: $cvutils.getTranslatedFactorDesc(selectedFactor) }'>
          Caller Verification: %{ factorDescription }
        </v-card-title>

        <div class="mb-5 pa-5">
          <!-- display predefined messages by default but hide if a custom message as selected -->
          <v-expand-transition>
            <div v-if="!useCustomMessage">
              <v-select
                id="selectedMessage"
                :item-text="item => item[$language.current] || item['en']"
                :items="featureFlags.cibaPredefinedMessages"
                v-model="selectedMessage"
                outlined
                color="#333"
                :label="$gettext('Select a message to send to the caller')"
                item-color="secondary"
              ></v-select>
            </div>
          </v-expand-transition>
          <!-- display a custom message dialog if a custom message was enabled -->
          <v-expand-transition>
            <div v-if="useCustomMessage">
              <!-- Input field for the custom message -->
              <v-text-field
                v-model="customMessage"
                name="customMessage"
                id="customMessage"
                ref="customMessage"
                outlined
                color="#333"
                class="my-4"
                persistent-hint
                autocomplete="off"
                :clearable="true"
                :label="$gettext('Custom message')"
                :hint="$gettext('Enter a custom message to send to the caller with the authentication request.')"
                @keyup.enter="verifyCallerCIBA()"
              >
              </v-text-field>
            </div>
          </v-expand-transition>

          <!-- display a checkbox to allow selection of a custom message -->
          <v-checkbox
            v-if="featureFlags.cibaCustomMessageEnabled"
            id="custom_message"
            v-model="useCustomMessage"
            dense
            :label="$gettext('Send custom message')"
          ></v-checkbox>

        </div>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Button to perform the actual caller verification against the API -->
          <v-btn
            color="success"
            id="ciba_verify"
            @click="verifyCallerCIBA()"
          >
            <translate>Verify</translate>
          </v-btn>

          <!-- Cancel button to abort this verification dialog -->
          <v-btn
            color="primary"
            id="ciba_close"
            @click="dialogCIBAMessage = false"
          >
            <translate>Close</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Poll Notification Verification Dialog -->
    <v-dialog
      v-model="dialogVerifyPoll"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title v-if="selectedFactor !== null" v-translate='{factorDescription: $cvutils.getTranslatedFactorDesc(selectedFactor) }'>
          Caller Verification: %{ factorDescription }
        </v-card-title>

          <v-card-text v-if="pollDialogText !== null">
            <label>{{ pollDialogText }}</label>
          </v-card-text>

        <div v-if="pushExpiresCountdown !== null && deviceDeliveryMethod === 'link'" class="ml-5">
          <div v-if="displayWeblinkMode=='url' || displayWeblinkMode=='both'">
            <label>{{ $gettext('Verification URL') }}</label>
            <v-chip
              class="ma-2"
              color="success"
              label
              outlined
            >
            {{ verificationUrl }}
            </v-chip>
            <v-btn

              icon
              color="success"
              @click="copyToClipBoard">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
            <br />
          </div>
          <div v-if="displayWeblinkMode=='code' || displayWeblinkMode=='both'">
            <label>{{ $gettext('User Code') }}</label>
            <v-chip
              class="ma-2"
              color="success"
              label
              outlined
            >
            {{ verificationUrlCode }}
            </v-chip>
            <v-btn
              icon
              color="success"
              @click="copyCodeToClipBoard">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </div>

        <!-- push verification challenge e.g. number challenge -->
        <div v-if="pushChallenge !== null" id="push_challenge" class="ml-5">
          <translate>Push Verification Challenge Code:</translate>
          <v-chip outlined label class="ml-5" color="primary">
            {{pushChallenge}}
          </v-chip>
        </div>

        <!-- Status of the PUSH Notification (Waiting, Success, Rejected) -->
        <div id="push_result" class="ma-5">
          {{ pushMessage }}
          <svg id="push_success" v-if="pushResult !== null && pushResult === 'SUCCESS'"  width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-check-circle-fill float-right mr-3 success--text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"></path>
          </svg>
          <svg id="push_waiting" v-if="pushResult !== null && pushResult === 'WAITING'" width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-hourglass-split info--text mr-3 float-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0c0 .701.478 1.236 1.011 1.492A3.5 3.5 0 0 1 11.5 13s-.866-1.299-3-1.48V8.35z"/>
          </svg>
          <svg id="push_reject" v-if="pushResult !== null && pushResult !== 'SUCCESS' && pushResult !== 'WAITING'"  width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-x-circle-fill float-right mr-3 error--text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"/>
          </svg>
        </div>

        <!-- PUSH Verification Countdown UI -->
        <div v-if="pushExpiresCountdown !== null" class="text-center my-2">
          <v-divider></v-divider>
          <div id="push_countdown" class="pa-4">
            <v-progress-linear height="40" color="primary" :value="pushExpiresProgress">
              <strong>{{ pushExpiresCountdown }}</strong>
            </v-progress-linear>
          </div>
        </div>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Close button to exit this verification dialog -->
          <v-btn
            color="primary"
            id="verify_push_close"
            @click="dialogVerifyPoll = false"
          >
            <translate>Close</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- email delivery override dialog -->
    <v-dialog
      v-model="dialogEmailOverride"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title id="email_override_title" v-translate>
          Set email address to send verification link to
        </v-card-title>

        <div class="ma-5">
          <!-- Email Override Dialog -->
          <v-text-field
            v-model="deliveryOverrideValue"
            name="emailOverride"
            :label="$gettext('Enter email address')"
            id="emailOverride"
            ref="emailOverride"
            outlined
            color="#333"
            class="my-4"
            autocomplete="off"
            :clearable="true"
            :rules="emailRules"
            @keyup.enter="deliveryOverrideSet()"
          >
          </v-text-field>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Button to perform the actual caller verification against the API -->
          <v-btn
            color="success"
            id="email_override_update"
            :disabled="!emailValid"
            @click="deliveryOverrideSet()"
          >
            <translate>Update</translate>
          </v-btn>

          <!-- Cancel button to abort this verification dialog -->
          <v-btn
            color="primary"
            id="email_override_cancel"
            @click="dialogEmailOverride = false"
          >
            <translate>Cancel</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- phone delivery override dialog -->
    <v-dialog
      v-model="dialogPhoneOverride"
      persistent
      max-width="625"
    >
      <v-card>
        <v-card-title id="phone_override_title" v-translate>
          Set phone number to send verification link to
        </v-card-title>

        <div class="ma-5">
          <!-- Phone Override Dialog -->
          <vue-tel-input-vuetify
            v-model="deliveryOverrideValue"
            :inputId="'delivery_phone'"
            :inputvalue="deliveryOverrideValue"
            outlined
            dense
            :mode="'international'"
            :defaultCountry="'US'"
            :onlyCountries="['CA', 'US']"
            :selectLabel="$gettext('Country')"
            autocomplete="off"
            required
            :placeholder="$gettext('Enter a phone number')"
            :label="$gettext('Enter a phone number')"
            appendIcon="$dropdown"
            @input="onPhoneInput"
            >
          </vue-tel-input-vuetify>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- Button to perform the actual caller verification against the API -->
          <v-btn
            color="success"
            id="phone_override_update"
            :disabled="!(deliveryPhone.valid || !deliveryOverrideValue)"
            @click="deliveryOverrideSet()"
          >
            <translate>Update</translate>
          </v-btn>

          <!-- Cancel button to abort this verification dialog -->
          <v-btn
            color="primary"
            id="phone_override_cancel"
            @click="dialogPhoneOverride = false"
          >
            <translate>Cancel</translate>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>

</template>

<script>
import {translate} from 'vue-gettext';
const {gettext: $gettext, gettextInterpolate} = translate;

export default {
  name: 'VerifyUser',

  // Component properties
  //
  // profile - project object for the selected user.
  // value   - controls if the dialog is visible or hidden.
  //
  props: {
    profile: Object,
    value: Boolean,
    quickVerifyFactorId: String,
    featureFlags: Object,
    deliveryMethods: Array
  },
  watch: {
    quickVerifyFactorId: function(factorId) {
      if(factorId !== null) {
        this.verifyCallerInitiate(factorId);
      }
      // reset the trigger property so the next QuickVerify triggers this watch again.
      this.$emit('qv_reset');
    },
    deliveryOverrideValue: function (mail) {
      if (this.deliveryOverrideMethod === 'email') {
        if (mail && mail !== '') {
          this.emailRules = [ value => {
            if (/.+@.+\..+/.test(value)) return true
            return 'E-mail must be valid.'
          }];
          this.emailValid = this.$refs.emailOverride.validate();
        }
        else if (!mail || mail === '') {
          this.emailRules = []
          this.emailValid = true;
        }
      }
    }
  },
  computed: {
    // expose a computed parameter that returns the :value property of this
    // component on retrieval and emits an close event to the parent to close
    // the dialog when set to 'false'.
    dialogSelectMethod: {
      get() {
        return this.value;
      },
      set(value) {
        if(!value) {
          this.$emit('close', 'mfa-verify');
        }
      }
    },
    responseRules() {
      if(this.selectedFactor.type === 'question') {
        return [];
      }
      else if(this.selectedFactor.provider === 'RSA') {
        return this.rsaRules;
      }
      else {
        return this.otpRules;
      }
    },
    // FIXME: we should make this a return property from our API call so we don't have to do this.
    callerName() {
      if(this.profile && this.profile.profile) {
        return this.profile.profile.firstName + ' ' + this.profile.profile.lastName;
      }
      else {
        return "";
      }
    },
    phoneLinkLabel() {
      let phoneLabel = $gettext('SMS link to caller.')
      let phoneNumber = this.devicePhoneOverride || this.deviceSmsNumber;
      if(phoneNumber) {
        phoneLabel = phoneLabel + '  (' + phoneNumber + ')';
      }
      return phoneLabel;
    },
    emailLinkLabel() {
      let emailLabel = $gettext('Email link to caller.')
      let emailAddress = this.deviceEmailOverride || this.deviceEmailAddr;
      if(emailAddress) {
        emailLabel = emailLabel + '  (' + emailAddress + ')';
      }
      return emailLabel;
    },
    // filter factors to only active, pending and disabled for the selected customer
    activePendingDisabledFactors() {
      let activePendingDisabled = {};
      // make sure we have a valid profile and MFA object first and foremost.
      if(this.profile && this.profile.mfa) {
        // loop over all factors, and filter out active and pending factors.
        for(let key in this.profile.mfa.factors) {
          let factor = this.profile.mfa.factors[key];
          if(['ACTIVE', 'PENDING_ACTIVATION', 'DISABLED'].includes(factor.status)) {
            activePendingDisabled[factor.id] = factor;
          }
        }
      }
      return activePendingDisabled;
    },
    // determine the verification link display mode
    displayWeblinkMode(){
      return this.featureFlags.displayWeblinkMode;
    },
    // computed property to determine if the Verify button should be
    // disabled or enabled.
    responseInvalid() {
      // make sure we have some sort of response at a minimum
      if(this.callerResponse && this.callerResponse.length > 0) {
        // if this.responseLengthMin is not set, we are dealing with a security question
        // so long as we have one character, let it through.
        if(!this.responseLengthMin) {
          return false; // button is not disabled (enabled)
        }

        // select a character set based on the token type.
        let charset = '[0-9]'; // most OTP's are numeric only.
        if(this.selectedFactor.provider === 'RSA') {
          // RSA tokens support alphanumeric.
          charset = '[0-9a-zA-Z]';
        }
        // regex to make sure we have between min and max characters.
        let pattern = `^(${charset}{${this.responseLengthMin},${this.responseLengthMax}})$`
        return !(RegExp(pattern).test(this.callerResponse))
      }
      return true;
    }
  },
  data: () => ({
    selectedFactor: {},
    webhooks: [],
    selectedWebhook: null,

    dialogVerifyPoll: false,
    dialogVerifyResponse: false,
    dialogCIBAMessage: false,
    dialogDeviceFlowDelivery: false,
    dialogEmailOverride: false,
    dialogPhoneOverride: false,

    // attributes for the caller response input dialog/field.
    showResponse: false,
    callerResponse: null,
    callerVerified: null,
    verificationMessage: null,
    responseHint: null,
    responseLengthMin: null,
    responseLengthMax: null,
    responseLabel: null,
    verifyInProgress: false,

    // push notification variables/parameters
    pollDialogText: null,
    pushMessage: null,
    pushResult: 'WAITING',
    pushExpires: null,
    pushExpiresCountdown: null,
    pushExpiresProgress: 0,
    pushChallenge: null,
    transactionId: null,
    transactionType: null,

    // used for sending messages along with the custom_app push notification.
    selectedMessage: null,
    customMessage: null,
    useCustomMessage: false,

    // used for device flow
    deviceDeliveryMethod: null,
    verificationUrl: null,
    verificationUrlCode: null,
    deliveryOverrideValue: null,
    deviceEmailOverride: null,
    devicePhoneOverride: null,
    deviceSmsNumber: null, 
    deviceEmailAddr: null,
    emailRules: [],
    deliveryPhone: {
      number: '',
      valid: false,
      country: undefined
    },
    emailValid: false,

    rsaRules: [
      v => (!v || /^([0-9a-zA-Z])*$/.test(v)) || $gettext('Code must contain letters and numbers only.')
    ],
    otpRules: [
      v => (!v || /^([0-9])*$/.test(v)) || $gettext('Code must contain numbers only.')
    ],

    otpFactors: ['token:software:totp','token:hotp','sms','email','token']
  }),
  methods: {
    onPhoneInput(formattedNumber, {number, valid, country}) {
      this.deliveryPhone.number = number.international;
      this.deliveryPhone.valid = valid;
      this.deliveryPhone.country = country.name;
    },
    // Start the caller verification process, trigging any challenges and
    // displaying the verification dialog.
    verifyCallerInitiate: function(factorId) {
      // Reset the TOTP validation status and message
      this.callerResponse = null;
      this.callerVerified = null;
      this.verificationMessage = null;
      this.pushChallenge = null;
      this.transactionId = null;

      // keep track of the selected factor for verification purposes.
      this.selectedFactor = this.profile.mfa.factors[factorId];
      if( this.featureFlags.deviceFlowDeliverySMS && this.$cvutils.getFactorProfileAttribute(this.selectedFactor, 'phone_number')){
        let phone_number = this.$cvutils.getFactorProfileAttribute(this.selectedFactor, 'phone_number');
        this.deviceSmsNumber = phone_number;
      }
      if( this.featureFlags.deviceFlowDeliveryEmail && this.$cvutils.getFactorProfileAttribute(this.selectedFactor, 'email')){
        let emailAddress = this.$cvutils.getFactorProfileAttribute(this.selectedFactor, 'email');
        this.deviceEmailAddr = emailAddress;
      }
      // only show sms as an option if the feature is enabled, and we either have a number or we have phone override enabled.
      var enableSMSDelivery = this.featureFlags.deviceFlowDeliverySMS && (this.featureFlags.deviceFlowPhoneOverride || this.deviceSmsNumber);
      if(enableSMSDelivery) {
        this.webhooks.push({'value': 'sms', 'text': this.phoneLinkLabel });
      }

      // only show email as an option if the feature is enabled, and we either have a number or we have email override enabled.
      var enableEmailDelivery = this.featureFlags.deviceFlowDeliveryEmail && (this.featureFlags.deviceFlowEmailOverride || this.deviceEmailAddr);
      if(enableEmailDelivery) {
        this.webhooks.push({'value': 'email', 'text': this.emailLinkLabel });
      }

      this.deliveryMethods.forEach((onemethod)=>{
        this.webhooks.push({'value':onemethod.name,'text':onemethod.description});
      });
        

      if (this.selectedFactor) {

        // close the verification method selection dialog
        this.dialogSelectMethod = false;

        // Verify an OTP factor (Software TOTP, SMS, Email)
        if (this.otpFactors.includes(this.selectedFactor.type)) {

          const translatedLabel = $gettext('%{factor} Code')
          this.responseLabel = gettextInterpolate(translatedLabel, {factor: this.$cvutils.getTranslatedFactorDesc(this.selectedFactor) })
          this.responseLengthMin = 6; /* min length */
          this.responseLengthMax = 6; /* max length */

          // determine the message to display based upon the type of OTP verification we are going to perform.
          if(this.selectedFactor.type === 'token:software:totp' || this.selectedFactor.type === 'token:hotp') {
            this.responseHint = $gettext('This is usually a 6 digit code within the authentication mobile application.');

            // totp code, no challenge needs to be sent to the caller, just display the entry dialog.
            this.dialogVerifyResponse = true;

            // Set focus to the input field for the caller response.
            setTimeout(() => { this.$refs.callerResponse.focus(); }, 200);
          }
          else if(this.selectedFactor.type === 'token' && this.selectedFactor.provider === 'RSA') {
            this.responseHint = $gettext('The RSA code is a combination of token PIN plus the current token code.');

            // no challenge needs to be sent to the caller, just display the entry dialog.
            this.dialogVerifyResponse = true;
            this.responseLengthMin = 4; /* min length */
            this.responseLengthMax = 16; /* max length */

            // Set focus to the input field for the caller response.
            setTimeout(() => { this.$refs.callerResponse.focus(); }, 200);
          }
          // SMS & Email need us to send a code to the caller via an API call first.
          else if(['sms','email'].includes(this.selectedFactor.type)) {
            if(this.selectedFactor.type === 'sms') {
              const translated = $gettext('A 6 digit code has been sent to the caller via SMS to: %{phone_number}');
              this.responseHint = gettextInterpolate(translated, {phone_number: this.selectedFactor.profile.phone_number});
            }
            else if(this.selectedFactor.type === 'email') {
              const translated = $gettext('A 6 digit code has been emailed to the caller at: %{email}');
              this.responseHint = gettextInterpolate(translated, {email: this.selectedFactor.profile.email});
            }

            // determine error dialog title based onw the type of validation we are doing.
            const dialogTitle = (this.selectedFactor.type === 'sms') ? $gettext('SMS OTP Verification Failed') : $gettext('Email OTP Verification Failed');

            // for email and sms based opt, we need to trigger the sending of the code first, do that here.
            this.$ajax.post(`/api/v1/users/${this.profile.id}/factors/${this.selectedFactor.id}/verify`).then(
              response => {

                // handle the case when we don't get a valid response back, but we got success HTTP code,
                // but an unexpected response body, present a generic Failed to send OTP message.
                const message = (response.data && response.data.message) ? response.data.message : 'Unknown reason.';
                if (!response.data || !response.data.status || response.data.result != 'CHALLENGE') {
                  this.$emit('notify', {
                    title: $gettext('Failed to send OTP challenge'),
                    body: $gettext(message),
                    type: 'error',
                    timeout: -1
                  });
                  return;
                }

                // CLAIM: if we got here, we got the expected response back, so we can only assume
                // the code was sent to the user via SMS or Email correctly.  Now we need to present
                // the help desk agent with a dialog to enter the caller response, close the selection
                // dialog and open up the verification dialog.
                this.dialogVerifyResponse = true;

                // get the transaction id (if present) for OOB flows.
                this.transactionId = response.data.transactionId

                // Set focus to the input field for the caller response.
                setTimeout(() => { this.$refs.callerResponse.focus(); }, 200);
              }
            ).catch(
              // Handle when we get back some sort of HTTP error response.
              error => {
                // prepare an error notification
                let notification = {
                  title: dialogTitle,
                  type: 'error',
                  body: '',
                  timeout: null,
                  showAuthBtn: false
                }

                // get more detailed error information (if we have it)
                if(error.response) {
                  const response_code = error.response.status;
                  const response_data = error.response.data;

                  // 401 unauthorized typically does not contain a message so include a default.
                  if(response_code === 401) {
                    notification.body = $gettext(response_data.message || 'Invalid request token, please re-login.');

                    // provide a login button directly in the dialog as well.
                    notification.showAuthBtn = true;
                    notification.timeout = -1;
                  }
                  else {
                    notification.body = $gettext(response_data.message || 'Unknown error.');
                  }
                }
                else {
                  // error, but no details, provide what we can.
                  notification.body = $gettext('An error occurred while attempting to send the OTP code.');
                  console.error(`There was an unknown error: ${error}`);
                }

                // display error notification to the user
                this.$emit('notify', notification);
              }
            ); // end - trigger ajax request to send sms/email otp
          } // end - SMS / Email OTP.
        }
        else if (this.selectedFactor.type === 'push') {
          // Trigger the PUSH and poll for the result
          this.$ajax.post(`/api/v1/users/${this.profile.id}/factors/${this.selectedFactor.id}/verify`).then(
            response => {
              // handle the case when we don't get a valid response back, but we got success HTTP code,
              // but an unexpected response body, present a generic Failed to send Push message.
              const message = (response.data && response.data.message) ? response.data.message : 'Unknown reason.';
              if (!response.data || !response.data.status || !response.data.transactionId) {
                this.$emit('notify', {
                  title: $gettext('Caller Verify Failed'),
                  body: $gettext(message),
                  type: 'error',
                  timeout: -1
                });
                return;
              }

              // CLAIM if we got here the push notification was sent, so no we can extract the
              // transaction ID in order to poll for a response from the caller.
              this.transactionId = response.data.transactionId;
              this.transactionType = 'push';
              this.pushChallenge = response.data.challengeCode; // number challenge (if present)
              if (this.pushChallenge) {
                this.pollDialogText = $gettext('Ask the caller to select or enter the push challenge code shown below.');
              }
              else {
                this.pollDialogText = null;
                this.pushChallenge = null;
              }

              // Check for polling status at the configured interval
              this.pushMessage = $gettext(response.data.message); // display initial push notification waiting message.
              this.pushResult = response.data.result;
              setTimeout(this.pollForVerifyResult, 5000);

              // Trigger the countdown timer, if 'expiresAt' exists
              const expiresAt = response.data.expiresAt;
              if (expiresAt) {
                this.pushExpires = expiresAt;
                setTimeout(this.expiresCountdown, 1000);
              }

              // Push notification sent, display the push verification dialog.
              this.dialogVerifyPoll = true;
            }
          ).catch(
            // Handle when we get back some sort of HTTP error response.
            error => {
              // prepare an error notification
              let notification = {
                title: $gettext('Caller Verify Failed'),
                type: 'error',
                body: '',
                timeout: null,
                showAuthBtn: false
              }

              // get more detailed error information (if we have it)
              if(error.response) {
                const response_code = error.response.status;
                const response_data = error.response.data;

                // 401 unauthorized typically does not contain a message so include a default.
                if(response_code === 401) {
                  notification.body = $gettext(response_data.message || 'Invalid request token, please re-login.');

                  // provide a login button directly in the dialog as well.
                  notification.showAuthBtn = true;
                  notification.timeout = -1;
                }
                else {
                  notification.body = $gettext(response_data.message || 'Unknown error.');
                }
              }
              else {
                // error, but no details, provide what we can.
                notification.body = $gettext('An error occurred while attempting to trigger the push notification.');
                console.error(`There was an unknown error: ${error}`);
              }

              // display error notification to the user
              this.$emit('notify', notification);
            }
          );
        }
        else if (this.selectedFactor.type === 'device') {
          // reset parameters.
          this.deviceDeliveryMethod = null;
          this.selectedWebhook = null;
          this.verificationUrl = null;
          this.dialogEmailOverride = false;
          this.dialogPhoneOverride = false;
          this.deliveryOverrideValue = null;

          // if we don't have email or sms configured default to link
          if(!this.featureFlags.deviceFlowDeliverySMS && !this.featureFlags.deviceFlowDeliveryEmail) {
            this.deviceDeliveryMethod = 'link';
          }

          // load defaults for the email a phone number for device delivery.
          this.deviceSmsNumber = this.$cvutils.getFactorProfileAttribute(this.selectedFactor, 'phone_number');
          this.deviceEmailAddr = this.$cvutils.getFactorProfileAttribute(this.selectedFactor, 'email');

          // display the delivery method selection dialog
          this.dialogDeviceFlowDelivery = true;
        }
        // handle CIBA flow.
        else if(this.selectedFactor.type === 'custom_app') {

          // reset any message parameters.
          this.useCustomMessage = false;
          this.customMessage = null;
          this.selectedMessage = null;

          // if we have more then one predefined message or we have custom messages enabled, then display a message
          // section dialog for the agent to pick from or provide custom one.
          if(this.featureFlags.cibaCustomMessageEnabled || this.featureFlags.cibaPredefinedMessages.length > 1) {
            this.dialogCIBAMessage = true;
          }
          // otherwise just move right to the push.
          else {
            this.verifyCallerCIBA();
          }
        }
        // Security Question Verification method.
        else if (this.selectedFactor.type === 'question') {
          this.responseLabel = $gettext('Answer');
          this.responseHint = $gettext('Enter the callers answer above');
          this.responseLengthMin = null;
          this.responseLengthMax = null;

          // Security Question: Just display the entry dialog.
          this.dialogVerifyResponse = true;
        }
      }
      // Unable to retrieve the selected factor details - this should be impossible
      // display and error, and let them try a different factor that perhaps works.
      else {
        this.$emit('notify', {
          title: $gettext('Verification Error'),
          body: $gettext('Unable to retrieve the selected factor details.'),
          type: 'error',
          timeout: -1
        });
      }
    },
    // Function to poll for the response from verification attempt (push, ciba, or device)
    pollForVerifyResult: function() {
      // Stop polling if the dialog has been closed
      if (!this.dialogVerifyPoll) {
        return;
      }

      if (this.transactionId !== null) {

        let verifyTransactionUrl = `/api/v1/users/${this.profile.id}/factors/${this.selectedFactor.id}/transactions/${this.transactionId}`;
        let verifyTransactionParams = null;
        if (this.transactionType === 'ciba') {
          verifyTransactionParams = { params: {login: this.profile.profile.login} };
        }

        this.$ajax.get(verifyTransactionUrl, verifyTransactionParams).then(
          response => {
            // handle the case when we don't get a valid response back, but we got success HTTP code,
            // but an unexpected response body, present a generic push verification failed message.
            const message = (response.data && response.data.message) ? response.data.message : 'Unknown';
            if (!response.data || !response.data.status) {
              // Dismiss the verification modal
              this.dialogVerifyPoll = false;

              // send a notification error message
              this.$emit('notify', {
                title: $gettext('Caller Verify Failed'),
                body: $gettext(message),
                type: 'error',
                timeout: -1
              });
              return;
            }

            // valid data returned from push notification status check.
            const result = response.data.result;

            // still waiting for push notification response
            if (result && result === 'WAITING') {
              this.pushMessage = $gettext(message); // display waiting message.
              this.pushResult = response.data.result;

              // try again in 5 seconds.
              setTimeout(this.pollForVerifyResult, 5000);
            }
            // we got back a non-waiting response, display the result.
            else if (result) {
              this.transactionId = this.transactionType = null;
              this.pushMessage = $gettext(message); // display result messages -- likely success/failure
              this.pushResult = response.data.result;
              this.pollDialogText = null;
              if(this.pushResult == "SUCCESS") {
                this.$emit('verifySuccess', this.profile, this.selectedFactor);
              }
              this.selectedWebhook = null;
            }
          }
        ).catch(
          // Handle when we get back some sort of HTTP error response.
          error => {
            // Close the polling for push notification dialog.
            this.dialogVerifyPoll = false;

            // prepare an error notification
            let notification = {
              title: $gettext('Caller Verify Failed'),
              type: 'error',
              body: '',
              timeout: null,
              showAuthBtn: false
            }

            // get more detailed error information (if we have it)
            if(error.response) {
              const response_code = error.response.status;
              const response_data = error.response.data;

              // 401 unauthorized typically does not contain a message so include a default.
              if(response_code === 401) {
                notification.body = $gettext(response_data.message || 'Invalid request token, please re-login.');

                // 401 on device poll could be invalid user logged in using the device token.
                // in this case we don't want to include the auth button and we want the dialog
                // to auto-dismiss.
                if(response_data.result == 'FAILED') {
                  notification.showAuthBtn = false;
                }
                // assume its an invalid access token.
                else {
                  // provide a login button directly in the dialog as well.
                  notification.showAuthBtn = true;
                  notification.timeout = -1;
                }
              }
              else {
                notification.body = $gettext(response_data.message || 'Unknown error.');
              }
            }
            else {
              // error, but no details, provide what we can.
              notification.body = $gettext('An error occurred polling for the push notification result.');
              console.error(`There was an unknown error: ${error}`);
            }

            // display error notification to the user
            this.$emit('notify', notification);
          }
        );
      }
      else {
        // no transaction id, we can't pool, kill this dialog.
        this.dialogVerifyPoll = false;

        // display an error notification saying we can't poll.
        this.$emit('notify', {
          title: $gettext('MFA Verification Error'),
          body: $gettext('Unable to poll for push verification result.'),
          type: 'error',
          timeout: -1
        });
      }
    },
    // function to handle the push countdown timer
    expiresCountdown: function() {
      let expiresAt = this.pushExpires;

      if (this.pushResult === 'SUCCESS' || this.pushResult === 'REJECTED') {
        this.pushExpiresCountdown = null;
        this.pushChallenge = null; // clear challenge display as well
        return;
      }

      if (expiresAt === undefined || expiresAt === null || expiresAt === '') {
        this.pushExpiresCountdown = null;

        if (this.dialogVerifyPoll) {
          this.dialogVerifyPoll = false;

          this.$emit('notify', {
            title: $gettext('Caller Verification Timeout'),
            body: $gettext('The caller verification has timed out.'),
            type: 'warning',
            timeout: 10000
          });
        }
        return;
      }

      const now = new Date();
      const expiresDate = new Date(expiresAt);

      // Calculate the difference in milliseconds
      const difference = expiresDate - now;

      // Check if we have already expired
      if (difference <= 0) {
        this.pushExpiresCountdown = null;

        // Close the dialog if still visible
        if (this.dialogVerifyPoll) {
          this.dialogVerifyPoll = false;

          this.$emit('notify', {
            title: $gettext('Caller Verification Timeout'),
            body: $gettext('The caller verification has timed out.'),
            type: 'warning',
            timeout: 10000
          });
        }
        return;
      }

      // Calculate the difference in seconds
      const diffSeconds = difference / 1000;

      // Push expiry is 5 minutes (300 seconds)
      const progress = Math.ceil((diffSeconds / 300) * 100);
      this.pushExpiresProgress = progress;

      // Calculate the difference in minutes
      const minutes = diffSeconds / 60;
      let minutesRemaining = Math.trunc(minutes);
      if (minutesRemaining < 10) {
        minutesRemaining = `0${minutesRemaining}`;
      }

      // Calculate the remaining seconds
      const seconds = (minutes - minutesRemaining) * 60;
      let secondsRemaining = Math.trunc(seconds);
      if (secondsRemaining < 10) {
        secondsRemaining = `0${secondsRemaining}`;
      }

      this.pushExpiresCountdown = `${minutesRemaining}:${secondsRemaining} ` + $gettext('min(s)');

      // update the countdown in 500 ms.
      setTimeout(this.expiresCountdown, 500);
    },
    updateWebhookDesc: function(hookId) {
      for(var i = 0; i < this.webhooks.length; i++) {
        if(this.webhooks[i]['value'] === hookId) {
          if(hookId == 'sms') {
            this.webhooks[i]['text'] = this.phoneLinkLabel;
          }
          else if(hookId == 'email') {
            let newEmailLabel = this.emailLinkLabel;
            this.webhooks[i]['text'] = newEmailLabel;
          }
        }
      }
    },
    clearSelectedWebhook(){
      this.selectedWebhook = null;
    },

    deviceOverrideAttribute: function(method) {
      this.deliveryOverrideValue = null;
      this.deliveryOverrideMethod = method;

      // if email is selected, present the email override dialog.
      if(method === 'email') {
        this.dialogEmailOverride = true;
        // if we are overriding email, and we have already overridden it,
        // make sure we start with our overridden value.
        if(this.deviceEmailOverride) {
          this.deliveryOverrideValue = this.deviceEmailOverride;
        }
      }
      // else if phone is selected present the phone override dialog.
      else if(method === 'sms') {
        this.dialogPhoneOverride = true;
        // if we are overriding phone, and we have already overridden it,
        // make sure we start with our overridden value.
        if(this.devicePhoneOverride) {
          this.deliveryOverrideValue = this.devicePhoneOverride;
        }
      }

    },
    deliveryOverrideSet: function() {
      let selectedOldHook = this.selectedWebhook;
      this.selectedWebhook = null;
      if(this.deliveryOverrideValue && this.deliveryOverrideValue !== '') {
        if(this.deliveryOverrideMethod == 'sms') {
          this.devicePhoneOverride = this.deliveryOverrideValue;
        }
        else if (this.deliveryOverrideMethod == 'email') {
          this.deviceEmailOverride = this.deliveryOverrideValue;
        }
      }
      // cleared values fallback to system defaults.
      else {
        if(this.deliveryOverrideMethod == 'sms') {
          this.devicePhoneOverride = null;

        }
        else if (this.deliveryOverrideMethod == 'email') {
          this.deviceEmailOverride = null;
        }
      }
      this.updateWebhookDesc(this.deliveryOverrideMethod);
      this.dialogPhoneOverride = false;
      this.dialogEmailOverride = false;
      this.deliveryOverrideMethod = null;
      this.deliveryOverrideValue = null;
      this.selectedWebhook = selectedOldHook;
    },
    verifyCallerDevice: function() {

      // put a loading spinner on the verify button.
      this.verifyInProgress = true;

      // use the selected delivery method.
      let deviceChallengeBody = { delivery: this.deviceDeliveryMethod };

      if(this.selectedWebhook) {
        if( this.selectedWebhook !== 'sms' && this.selectedWebhook !== 'email'){
          deviceChallengeBody['webhookId'] = this.selectedWebhook;
        }
        else if(this.selectedWebhook === 'sms' ){
          if(this.devicePhoneOverride){
            deviceChallengeBody.delivery_phone = this.devicePhoneOverride;
          }
          deviceChallengeBody['delivery']=this.selectedWebhook;
        }
        else{
          // for the case sms/email, we need to explicitly tell the api the delivery method is sms/email so to avoid calling the webhook trigger
          deviceChallengeBody['delivery']=this.selectedWebhook;
          if(this.deviceEmailOverride){
            deviceChallengeBody.delivery_email = this.deviceEmailOverride;
          }
        }
      }


      // trigger the device flow, then poll for the result -- override the timeout to account for sms/email delivery verification.
      this.$ajax.post(`/api/v1/users/${this.profile.id}/factors/${this.selectedFactor.id}/verify`, deviceChallengeBody, { timeout: 20000 }).then(
        response => {

          // dismiss the selector dialog and spinner.
          this.dialogDeviceFlowDelivery = false;
          this.verifyInProgress = false;

          // handle the case when we don't get a valid response back, but we got success HTTP code,
          // but an unexpected response body, present a generic Failed to send Push message.
          const message = (response.data && response.data.message) ? response.data.message : 'Unknown reason.';
          if (!response.data || !response.data.status || !response.data.transactionId) {
            this.$emit('notify', {
              title: $gettext('Caller Verify Failed'),
              body: $gettext(message),
              type: 'error',
              timeout: -1
            });
            this.selectedWebhook = null;
            return;
          }

          // CLAIM if we got here the device flow has been started, so no we can extract the
          // transaction ID in order to poll for a response from the caller.
          this.transactionId = response.data.transactionId;
          this.transactionType = 'device';
          this.verificationUrl = response.data.verificationUrl;

          // get the real url by stripping off the parameter part
          let fullurl = new URL(this.verificationUrl);
          var urllink = fullurl.origin + fullurl.pathname
          this.verificationUrlCode = response.data.userCode;

          // Check for polling status at the configured interval
          if (this.deviceDeliveryMethod === 'webhook' && this.selectedWebhook === 'sms') {
            this.pollDialogText = $gettext("A verification link has to sent to the callers phone: ") +
            (this.devicePhoneOverride || this.deviceSmsNumber)
          }
          else if (this.deviceDeliveryMethod === 'webhook' && this.selectedWebhook === 'email') {
            this.pollDialogText = $gettext("A verification link has to sent to the callers email: ") +
            (this.deviceEmailOverride || this.deviceEmailAddr)
          }
          // display link
          else {
            if(this.displayWeblinkMode == 'url' ){
              this.pollDialogText = $gettext("Provide the following link to the caller to verify their identity:");
            }
            else if(this.displayWeblinkMode == 'code'){
              let translated = "Have the caller go to %{ urlLink } and enter the user code below:"
              this.pollDialogText = gettextInterpolate(translated, {urlLink: urllink})
            }
            else if(this.displayWeblinkMode == 'both'){
              this.pollDialogText = $gettext("Provide either the following link or code to the caller to verify their identity:");

            }
          }
          this.pushMessage = $gettext(response.data.message); // display initial push notification waiting message.
          this.pushResult = response.data.result;
          setTimeout(this.pollForVerifyResult, 5000);

          // Trigger the countdown timer, if 'expiresAt' exists
          const expiresAt = response.data.expiresAt;
          if (expiresAt) {
            this.pushExpires = expiresAt;
            setTimeout(this.expiresCountdown, 1000);
          }

          this.selectedWebhook = null;
          // device flow started, trigger the polling countdown timer.
          this.dialogVerifyPoll = true;
        }
      ).catch(
        // Handle when we get back some sort of HTTP error response.
        error => {

          // dismiss the selector dialog and spinner.
          this.dialogDeviceFlowDelivery = false;
          this.verifyInProgress = false;

          // prepare an error notification
          let notification = {
            title: $gettext('Device Verify Failed'),
            type: 'error',
            body: '',
            timeout: null,
            showAuthBtn: false
          }

          // get more detailed error information (if we have it)
          if(error.response) {
            const response_code = error.response.status;
            const response_data = error.response.data;

            // 401 unauthorized typically does not contain a message so include a default.
            if(response_code === 401) {
              notification.body = $gettext(response_data.message || 'Invalid request token, please re-login.');

              // provide a login button directly in the dialog as well.
              notification.showAuthBtn = true;
              notification.timeout = -1;
            }
            else {
              notification.body = $gettext(response_data.message || 'Unknown error.');
            }
          }
          else {
            // error, but no details, provide what we can.
            notification.body = $gettext('An error occurred while attempting to trigger the push notification.');
            console.error(`There was an unknown error: ${error}`);
          }

          // display error notification to the user
          this.$emit('notify', notification);
          this.selectedWebhook = null;
        }
      );
    },
    verifyCallerCIBA: function() {
      this.dialogCIBAMessage = false; /* in case it was opened */

      // start with the base request body.
      let cibaChallengeBody = {
        login: this.profile.profile.login,
        locale: this.profile.profile.locale || 'en_US'
      };

      // determine the message (if any) to include in the request.
      //
      // prefer the custom message if enabled.
      if(this.useCustomMessage && this.customMessage && this.customMessage.trim() !== '') {
        cibaChallengeBody['message'] = this.customMessage;
      }
      // otherwise use a predefined selected message (if any)
      else if(this.selectedMessage) {
        cibaChallengeBody['message_id'] = this.selectedMessage;
      }

      // start the CIBA verification flow, sending the login as part of the POST body.
      this.$ajax.post(`/api/v1/users/${this.profile.id}/factors/${this.selectedFactor.id}/verify`, cibaChallengeBody).then(
        response => {
          // handle the case when we don't get a valid response back, but we got success HTTP code,
          // but an unexpected response body, present a generic Failed to send Push message.
          const message = (response.data && response.data.message) ? response.data.message : 'Unknown reason.';
          if (!response.data || !response.data.status || !response.data.transactionId) {
            this.$emit('notify', {
              title: $gettext('Caller Verify Failed'),
              body: $gettext(message),
              type: 'error',
              timeout: -1
            });
            return;
          }

          // CLAIM if we got here the push notification was sent, so no we can extract the
          // transaction ID in order to poll for a response from the caller.
          this.transactionId = response.data.transactionId;
          this.transactionType = 'ciba';

          // Check for polling status at the configured interval
          this.pollDialogText = null
          this.pushMessage = $gettext(response.data.message); // display initial push notification waiting message.
          this.pushResult = response.data.result;
          setTimeout(this.pollForVerifyResult, 5000);

          // Trigger the countdown timer, if 'expiresAt' exists
          const expiresAt = response.data.expiresAt;
          if (expiresAt) {
            this.pushExpires = expiresAt;
            setTimeout(this.expiresCountdown, 1000);
          }

          // Push notification sent, display the push verification dialog.
          this.dialogVerifyPoll = true;
        }
      ).catch(
        // Handle when we get back some sort of HTTP error response.
        error => {
          // prepare an error notification
          let notification = {
            title: $gettext('Caller Verify Failed'),
            type: 'error',
            body: '',
            timeout: null,
            showAuthBtn: false
          }

          // get more detailed error information (if we have it)
          if(error.response) {
            const response_code = error.response.status;
            const response_data = error.response.data;

            // 401 unauthorized typically does not contain a message so include a default.
            if(response_code === 401) {
              notification.body = $gettext(response_data.message || 'Invalid request token, please re-login.');

              // provide a login button directly in the dialog as well.
              notification.showAuthBtn = true;
              notification.timeout = -1;
            }
            else {
              notification.body = $gettext(response_data.message || 'Unknown error.');
            }
          }
          else {
            // error, but no details, provide what we can.
            notification.body = $gettext('An error occurred while attempting to trigger the push notification.');
            console.error(`There was an unknown error: ${error}`);
          }

          // display error notification to the user
          this.$emit('notify', notification);
        }
      );
    },
    verifyCallerResponse: function() {
      // Ignore empty
      if (this.callerResponse === null || (this.callerResponse && this.callerResponse.trim() === '')) {
        return;
      }

      // show "in progress" indicator for the verification.
      this.verifyInProgress = true;

      // make sure the selected factor is a supported one for this verify function
      if(this.selectedFactor && this.selectedFactor.type &&
         (this.otpFactors.includes(this.selectedFactor.type) || this.selectedFactor.type === 'question')) {

        // get a local variable to handle if this is a security question verification or not.
        const isSecQ = (this.selectedFactor.type === 'question');

        let verifyPayload = null
        if(isSecQ) {
          verifyPayload = { answer: this.callerResponse }
        }
        else {
          verifyPayload = { code: this.callerResponse }
        }

        // if there is a transaction id, include it in the request.
        if(this.transactionId !== null) {
          verifyPayload.transactionId = this.transactionId;
        }

        this.$ajax.post(`/api/v1/users/${this.profile.id}/factors/${this.selectedFactor.id}/verify`,
          verifyPayload,
          { timeout: 20000 } // OTP oob flows can take a bit longer, so allow for that.
        ).then(response => { // Successful HTTP response from the verify call.

          // Clear "in progress" indicator now that we have an response.
          this.verifyInProgress = false;

          // handle the case when we don't get a valid response back, but we got success HTTP code,
          // but an unexpected response body, we can't verify the caller, so pop-up an error notification.
          const message = (response.data && response.data.message) ? response.data.message : 'Unknown reason';
          if (!response.data || !response.data.status) {
            // close the verification dialog and display error.
            this.dialogVerifyResponse = false;

            this.$emit('notify', {
              title: $gettext('Caller Verify Failed'),
              body: $gettext(message),
              type: 'error',
              timeout: -1
            });
            // dump a log in the console, this needs investigation.
            console.error(message);
            return;
          }

          // We received a success HTTP status from the API call, and the response is
          // well formatted --> Caller is Verified!!
          this.callerVerified = true;

          // emit the details of the success up the chain.
          this.$emit('verifySuccess', this.profile, this.selectedFactor)


          // For now we dump the response from the successful verification API call
          // directly to the user, eventually we should probably handle these messages
          // completely in the front end so we can change them without changing the API.
          this.verificationMessage = $gettext(message);

        }
        ).catch(
          // Failed HTTP response from the verify call.
          error => {
            // Clear "in progress" indicator now that we have an response - even tho its an error.
            this.verifyInProgress = false;

            // prepare an error notification
              let notification = {
                title: $gettext('Caller Verify Failed'),
                type: 'error',
                body: '',
                timeout: null,
                showAuthBtn: false
              }

            // Parse the error response from the API call, looking for a few particular codes.
            if (error.response) {
              const response_code = error.response.status;
              const response_data = error.response.data;

              // The response supplied by the caller invalid, provide a message to that effect.
              if (response_code === 403) {
                this.callerVerified = false; // flag the input field to show a red X

                // display a message depending on the type of response (code vs security question answer)
                if(isSecQ) {
                  this.verificationMessage = $gettext('Failed to verify caller:  The supplied answer was invalid.');
                }
                else {
                  this.verificationMessage = $gettext('Failed to verify caller:  The supplied code was invalid.');
                }
                // 403 - is not an error but a verification failure, so stop here - no notification required.
                return;
              }

              // 401 unauthorized typically does not contain a message so include a default.
              if(response_code === 401) {
                notification.body = $gettext(response_data.message || 'Invalid request token, please re-login.');

                // provide a login button directly in the dialog as well.
                notification.showAuthBtn = true;
                notification.timeout = -1;
              }
              else {
                notification.body = $gettext(response_data.message || 'Unknown error.');
              }
            }
            else {
              // error, but no details, provide what we can.
              notification.body = $gettext('An error occurred while attempting to verify the caller.');
              console.error(`There was an unknown error: ${error}`);
            }
            // display error notification to the user
            this.dialogVerifyResponse = false;
            this.$emit('notify', notification);
          }
        );
      }
      // should not be able to get into this else, but if we do, it means the factor is
      // not supported so display an error saying so.
      else {
        this.dialogVerifyResponse = false;

        this.$emit('notify', {
          title: $gettext('Caller Verify Failed'),
          body: $gettext('The selected factor is not supported, unable to verify the caller.'),
          type: 'warning'
        });
      }
    },
    copyToClipBoard() {
      navigator.clipboard.writeText(this.verificationUrl);
    },
    copyCodeToClipBoard() {
      navigator.clipboard.writeText(this.verificationUrlCode);
    }
  }
}
</script>
